<template>
  <div>
    <BaseLoader v-if="!$store.getters.isLoaded(['products'])" />

    <div v-else>
      <ProductForm :productId="productId" @saved="$router.push({ name: 'products' })" />
      <hr class="mt-5 mb-5" />
      <div class="container" v-if="salesStatistics">
        <h1 class="mb-4">Sales Statistics</h1>
        <ProductSalesChart :productId="productId" :weeksToShow="52" />
        <KioskProductsListing :productId="productId" />
      </div>
    </div>
  </div>
</template>

<script>
import KioskProductsListing from '@/components/KioskProductsListing'
import ProductForm from '@/components/ProductForm'
import ProductSalesChart from '@/components/ProductSalesChart'

export default {
  components: { ProductForm, ProductSalesChart, KioskProductsListing },
  computed: {
    productId() {
      return this.$route.params.productId
    },
    product() {
      const product = this.$store.state.products.find(p => p.id === this.$route.params.productId)
      if (typeof product !== 'undefined') return product
      return null
    },
    salesStatistics() {
      if (this.product && this.product.salesByWeek) {
        const { salesByWeek } = this.product
        const salesWeekKeys = Object.keys(salesByWeek).sort()
        const salesStatistics = salesWeekKeys.map((weekLabel, weekIndex) => {
          const amount = salesByWeek[weekLabel]
          const relevantWeeks = [weekLabel]
          for (let i = weekIndex > 3 ? 3 : weekIndex; i > 0; i--) {
            relevantWeeks.push(salesWeekKeys[weekIndex - i])
          }
          let averageSales = 0
          relevantWeeks.forEach(week => {
            averageSales += salesByWeek[week]
          })
          averageSales = parseFloat((averageSales / relevantWeeks.length).toFixed(1))
          return {
            weekLabel,
            amount,
            averageSales,
            relevantWeeks
          }
        })

        return salesStatistics
      } else {
        return null
      }
    }
  }
}
</script>
